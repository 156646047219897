*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.desc_title{
    color: #0a3079;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-family: inherit;
    font-weight: 500;
}
.desc_sub_title{
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #0a3079;
}
.desc_para{
    color: #0a3079;
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
}