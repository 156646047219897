* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.air_distribution_title {
  margin-top: 20px;
  color: #0a3079;
}

.air-distribution h5 {
  margin-top: 60px;
  color: #0a3079;
  font-weight: 200;
  font-size: 1.25rem;
  font-family: inherit;
}

.air-distribution h6 {
  color: #0a3079;
  font-weight: 400;
  font-size: 1rem;
  margin: .5em 0 3em 0;
  font-family: inherit;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
}

.accordion-button {
  font-size: 1.1rem;
  font-weight: 400;
  color: #0a3079;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
}

.accordion-body-link {
  text-decoration: none !important;
  font-size: .9em;
  font-weight: 200;
  line-height: 1.5;
  color: #071532;
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.airdistribution_accordion .accordion-body {
  padding: 10px;
}


/* popup style */
/* Popup styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  max-width: 800px;
  height: 80vh;
  overflow-y: auto;
  position: relative;
  margin-top: 20px;
}

.popup-left img {
  max-width: 100%;
  height: auto;
}

.popup-right {
  margin-left: 20px;
  flex: 1;
}

.close {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  cursor: pointer;
}

.accordion-header hr {
  margin: 0;
  /* border: 1px solid #0a3079; */
  width: 100%;
}

.popup_icon {
  width: 30px;
  height: 30px;
}

/* inner popup style */
.popup_main_title {
  color: #0a3079;
  font-size: 1.5em;
  margin: 1em 0 1em 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}

.popup_title {
  color: #0a3079;
  font-size: 1.3em;
  font-weight: 400;
  margin-bottom: 1em;
  font-family: inherit;
  line-height: 1.2;
  text-align: justify;
}

.title {
  color: #0a3079;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  margin: 10px 0;
  text-transform: none;
}

.td {
  color: #00386c;
  font-weight: bold;
  height: 2.5em;
  padding: 0 5px 0 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-collapse: collapse;
  font-size: 1rem;
}

.td-2 {
  color: #0A307C;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.th {
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #00386c;
  border-top: 1px solid #3cb9a5;
  width: 100%;
}

table tr:first-child td {
  border-top: 1px solid #3cb9a5;
}

table tr td {
  border-bottom: 1px solid #3cb9a5;
}

.list {
  list-style-type: circle;
  color: #0a3079;
  text-align: justify;
}

.list li {
  color: #0a3079;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify !important;
}

.pdf_para {
  color: #0a3079;
  font-weight: normal;
  margin-left: 10px;
  text-align: justify;
}

.product-content .row {
  border-bottom: 1px solid #3cb9a5;
  margin-top: 2px;
  margin-bottom: 5px;
}

.product-content .sub-title {
  color: #0a3079;
  font-size: 1.75rem;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
}

.head-para {
  color: #0a3079;
  font-weight: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}



























































@media screen and (max-width:767px) {
  .airdistribution_accordion .accordion {
    display: none !important;
  }
}

@media (max-width: 767px) {

  .popup-left,
  .popup-right {
    width: 100%;
    margin: 0 !important;
  }
}

@media (max-width: 767px) {
  .pdf_para {
    margin-left: 40px;
  }
}