* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.brochure_title {
    color: #0a3079;
    margin: 0 0 1.5rem 0;
    font-size: 2rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

.brochure_para {
    color: #0a3079;
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.2;
}

#brochures p {
    color: #0a3079;
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
    padding: 5px;
}

#brochures .accordion-button {
    color: #0a3079;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: inherit;
    line-height: 1.2;
}