* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.news_head {
    color: #0a3079;
    font-size: 2rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

.news_hr {
    margin-top: 10px;
    border-top: 1px solid rgba(10, 48, 121, .3);
}

.event_heading {
    color: #0a3079;
    font-size: 1.75;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

.event_content h4 {
    color: #0a3079;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    line-height: 1.2;
}

.event_content p {
    color: #0a3079;
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
}

.event_btn {
    padding: 5px;
    color: #00386c;
    border: 1px solid #00386c;
    text-decoration: none;
    background-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 1rem;
}

.event_btn:hover {
    background-color: #007bff;
    color: white;
}
.news_link_krantz{
    color: #007bff;
    text-decoration: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
}
.news-subpara{
    color: #0a3079;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
}
.news-arabtitle{
    font-weight: 400;
    color: #0a3079;
    font-size: 1.5rem;
    font-family: inherit;
    line-height: 1.5;
    text-align: justify;
}
.news-arabpara{
    color: #0a3079;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
}


@media screen and (max-width:800px) {
    #news_events .container{
        width: 90% !important;
    }
}