* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.privacy_title {
    color: #0a3079;
    font-size: 2.5;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

#privacy h3 {
    color: #0a3079;
    font-size: 1.75;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

#privacy p {
    color: #0a3079;
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
}

#privacy .privacy_disc li {
    color: #0a3079;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    list-style-type: disc;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.privacy_sub_title {
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #0a3079;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
}

.privacy_link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
}