* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.accordion-button {
    padding: 20px 0px 20px 0px;
}

.accordion-button {
    background: none;
    box-shadow: none;
    border: none;
    outline: none;
    font-weight: 400;
    color: #0a3079;
    font-size: 1.5rem;
    font-family: inherit;
    line-height: 1.2;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
}

.accordion-button:focus {
    box-shadow: none;
    background-color: transparent;
}

.accordion-button:hover {
    text-decoration: none;
    background-color:transparent;
}
.accordion-body{
    padding: 10px 0px 20px 0px;
    color: #0a3079;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
}
