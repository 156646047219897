* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#vedio h2 {
    color: #0a3079;
    margin: 0 0 1.5em 0;
    font-size: 2rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

#vedio h5 {
    color: #0a3079;
    margin: 0 0 1.5em 0;
    font-size: 1.25rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    text-align: justify;
}

.vedio_hr {
    margin-bottom: 4rem 0 4rem 0;
    border-top: 1px solid rgba(10, 48, 121, 1);
}