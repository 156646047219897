*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #44a2bc;
    border: none;
    border-radius: 0;
    transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
    background-color: #175dac;
}