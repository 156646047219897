.form-btn {
    background-color: #0a3079;
    height: 35px;
    width: auto;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    border-width: 0px;
    padding: 0 1em;
}

.form-group {
    padding: 5px;
}

.enquiry-form h1 {
    padding: 5px;
    font-size: 16px;
}

.form-btn {
    margin-left: 6px;
}

.enqform {
    box-shadow: 0px 0px 10px #00000021;
    padding: 20px 20px;
    height: auto;
}

.contact_hr {
    margin-top: 80px;
}

.contact_address {
    color: #0a3079;
    font-size: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contact_address p {
    color: #0a3079;
    font-size: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.contact_title {
    color: #0a3079;
    font-size: 2rem;
    font-family: inherit;
    font-weight: 500;
}

.contact_sub_title {
    color: #0a3079;
    font-size: 1.4rem;
    font-family: inherit;
    font-weight: 500;
}

.contact_para {
    color: #0a3079;
    font-size: .9rem;
}


@media screen and (max-width:768px) {
    #contact .container {
        margin-top: 80px !important;
        width: 90% !important;
    }

    #contact .contact_form {
        margin-top: 40px;
    }
}

@media screen and (max-width:1240px) {
    .india p {
        margin-left: 30px !important;
    }

    .germany p {
        margin-left: 30px !important;
    }
}