* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bg-text {
    background: rgba(251, 251, 251, 0.7);
    color: #0a3079;
    padding: 30px;
}

.bg-text h5 {
    font-weight: 900;
    font-family: inherit;
    line-height: 1.2;
    font-size: 2.5em;
}

.bg-text p {
    font-weight: 300;
    font-family: inherit;
    line-height: 1.2;
    font-size: 3em;
}

.carousel-item img {
    height: 550px;
    object-fit: cover;
    width: 100%;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .bg-text h5 {
        font-size: 2.5em;
    }

    .bg-text p {
        font-size: 2em;
    }

    .carousel-item img {
        height: 500px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .bg-text h5 {
        font-size: 2.1em;
    }

    .bg-text p {
        font-size: 2.2em;
    }

    .carousel-item img {
        height: 450px;
    }
}

@media (max-width: 767.98px) {
    .bg-text h5 {
        font-size: 1.4em;
    }

    .bg-text p {
        font-size: 1.2em;
    }

    .carousel-item img {
        height: 300px;
    }
}