* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    background-color: white;
    padding: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.navbar-brand {
    padding: 20px;
    padding-left: 190px;
}

.navbar-toggler {
    border: none;
    font-size: 1rem;
    font-weight: 400;
}

.offcanvas-header {
    display: flex;
    align-items: center;
}

.btn-close {
    font-size: 1rem;
    font-weight: 400;
    width: 15px;
    height: 12px;
    border: 1px solid gray !important;
    margin-right: 4px !important;
}

.offcanvas-close-text {
    font-size: 1rem;
    font-weight: 500;
}

.navbar-toggler-icon {
    width: 15px;
    height: 12px;
    margin-bottom: 4px;
}

.navbar-toggler:focus,
.btn-close:focus {
    box-shadow: none;
    outline: none;
    color: #0a3079;
}

.nav-link {
    color: #0a3079 !important;
    font-size: 15px;
    font-weight: 300;
    position: relative;
}

.dropdown-item {
    color: #0a3079;
    font-size: 15px;
    font-weight: 300;
}

.nav-link:hover,
.nav-link:active {
    color: black !important;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0px !important;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item:hover {
    background-color: transparent !important;
    color: #0056b3 !important;
}



@media (min-width:991px) {
    .nav-link::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background-color: #0a3079;
        visibility: hide;
        transition: 0.3s ease-in-out;
    }

    .nav-link:hover::before,
    .nav-link:active::before {
        width: 100%;
        visibility: visible;
    }

}

@media screen and (max-width:1100px) {
    .navbar-brand {
        padding-left: 50px;
    }
}

@media screen and (max-width:500px) {
    .navbar-brand {
        padding-left: 20px;
    }
}