* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer-container {
    width: 100%;
    background-color: white;
    height: 150px;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem 0;
    margin-top: 38px;
}

.footer-link {
    text-decoration: none;
    color: dark;
    margin: 0.5rem;
    padding: 0.5rem;
    color: #0a3079;
}

.footer-text {
    margin: 0.5rem;
    padding: 1rem;
    color: #0a3079;
}

.footer-link:hover {
    color: 0056b3;
}

.footer-text:hover {
    color: 0056b3;
}

.wh-me {
    text-decoration: none;
    display: flex;
}

.whup-num {
    color: #0a3079;
    margin-top: 5px;
}

@media screen and (max-width:426px) {
    .footer-link {
        padding: 0px;
    }
}