* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#certificates .container {
  max-width: 100% !important;
}

.certificate_hr {
  border-top: 1px solid #ccc;
}

#certificates .title {
  color: #0a3079;
  margin-bottom: 50px;
  font-size: 2rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  text-transform: capitalize;
}

#certificates p {
  color: #0a3079;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: justify;
  padding: 5px;
}

#certificates .accordion-button {
  color: #0a3079;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: inherit;
  line-height: 1.2;
}

@media (max-width: 576px) {
  #certificates .container {
    width: 90% !important;
    margin: auto;
    margin-top: 100px !important;
  }

  .accordion-item {
    display: block;
  }

  .accordion-button {
    width: 100%;
  }

  .accordion-body {
    width: 100%;
  }
}