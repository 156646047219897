* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about_service_title {
    margin-top: 25px;
    color: #0a3079;
    font-size: 2rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

.service_container {
    position: relative;
    color: white;
}

.service_text {
    position: absolute;
    top: 72%;
    font-size: 1.5rem;
    padding: 10px;
    font-weight: 400;
}

.hr_bottom {
    margin-top: 12em;
}






@media screen and (max-width:1420px) {
    .service_text {
        top: 65%;
        font-size: 1.3rem;
    }
}

@media screen and (max-width:1024px) {
    .service_text {
        top: 55%;
        font-size: 1.2rem;
    }
}

@media screen and (max-width:950px) {
    .service_text {
        top: 50%;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .service_text {
        top: 46% !important;
        font-size: 1rem;
    }
}


@media (max-width: 760px) {
    #about_service .container{
        width: 90% !important;
    }
    .service_image {
        margin-top: 15px !important;
    }

    .service_text {
        top: 68% !important;
        font-size: 1.3rem;
    }
}