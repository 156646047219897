* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.main_container{
    width: 75% !important;
    margin: auto;
}
.covid {
    color: red;
    font-size: 1.75rem;
}
.corona_title {
    padding: 4em 0 3em 0;
}
.prct_btn {
    background-color: #0f3a8c;
    border-color: #0f3a8c;
    border-style: solid;
    border-width: 1px;
    padding: 15px 30px;
    color: white;
    font-size: 1rem;
}

.title {
    color: #0a3079;
    font-weight: 300;
    font-size: 1em;
    text-transform: uppercase;
    font-family: inherit;
}
.about_head h4{
    color: #0a3079;
    font-weight: 400;
    font-family: inherit;
}
.about_head p{
    color: #0a3079;
    font-weight: 300;
    font-size: 1em;
    font-family: inherit;
    text-align: justify;
    margin-bottom: 3em;
}
.hr {
    border-top: 1px solid rgba(10, 48, 121, 1);
    margin-top: 1em;
}

.more_btn {
    color: #0a3079;
    border: 1px solid #0a3079;
    padding: 15px 30px;
    background-color: transparent;
    font-size: 1em;
}

.more_btn:hover {
    background-color: #007bff;
    color: white;
}

.product-image-container {
    position: relative;
}

.product-image {
    display: block;
    margin: 0 auto;
    height: auto;
}

.overlay-content {
    position: absolute;
    top: 10%;
    left: 5%;
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.view-products-btn {
    background-color: transparent;
    border-color: white;
    color: white;
    margin-top: 140px;
    padding: 15px 30px;
    border: 1px solid white;
    margin-left: 2em;
    font-size: 1rem;
    font-weight: 400;
}

.view-products-btn:hover {
    background-color: #007bff;
}

.content-title {
    font-size: 2em;
    font-weight: 300;
    font-family: inherit !important;
    line-height: 1.2;
    margin-left: 1em; 
}   
.refrence_content{
    color: #0a3079;
    font-weight: 300;
    font-size: 1.1em;
    margin: 5px 0 0 0;
    font-family: inherit;
    line-height: 1.2;
}








@media screen and (max-width: 1290px) {
    .view-products-btn {
        margin-top: 100px;
        padding: 15px 30px;
    }
}

@media screen and (max-width: 1200px) {
    .view-products-btn {
        margin-top: 80px;
        padding: 5px 15px;
    }

    .content-title {
        font-size: 1.5em;
    }

}

@media screen and (max-width: 945px) {
    .view-products-btn {
        margin-top: 50px;
        padding: 5px 15px;
    }

    .content-title {
        font-size: 1em;
    }

    .overlay-content {
        top: 10%;
        left: 3%;
        padding: 10px;
    }

}

@media screen and (max-width: 630px) {
    .view-products-btn {
        margin-top: 30px;
        padding: 5px;
    }

    .content-title {
        font-size: .8em;
    }

    .overlay-content {
        top: 10%;
        left: 3%;
        padding: 10px;
    }

}

@media screen and (max-width: 500px) {
    .view-products-btn {
        margin-left: 1;
        margin-top: 20px;
        padding: 4px;
        font-size: 0.8em;
    }

    .content-title {
        font-size: .7em;
    }

    .overlay-content {
        top: 10%;
        left: 3%;
        padding: 5px;
    }

}
@media screen and (max-width:767px) {
    .main_container{
        width: 90% !important;
        margin: auto;
    }
}
@media screen and (max-width:576px) {
    .ref_img{
        margin-top: 10px;
    }
}
@media screen and (max-width:768px) {
    .asi_part{
        margin-top: 15px;
    }
}

