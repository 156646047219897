*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.profile_title{
    color: #0a3079;
    font-size: 2em;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 100px;
}
.profile_para{
    color: #0a3079;
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.2;
}
.bold{
    font-weight: bolder;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #0a3079;
    font-size: 1rem;
}
.development_title{
    font-weight: 400;
    color: #0a3079;
    font-size: 1.5rem;
    font-family: inherit;
    margin-bottom: 50px;
}
.hr_bottom{
    margin-top: 4em;
}

#about_profile .accordion-button{
    color: #0a3079;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: inherit;
    line-height: 1.2;
}











@media screen and (max-width:600px) {
    #about_profile .container{
        width: 90% !important;
    }
}
@media screen and (max-width:1180px) {
    .profile_title{
        margin-top: 150px;
    }
}
@media screen and (max-width:992px) {
    .profile_title{
        margin-top: 100px;
    }
}