* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.technical_article_hr {
    margin-top: 50px;
}

.technical_article_title {
    color: #0a3079;
    margin: 0 0 1.5em 0;
    font-size: 2rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

.technical_article_para {
    font-weight: 300;
    color: #0a3079;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0px;
}

#technical_article .accordion-button {
    color: #0a3079;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: inherit;
    line-height: 1.2;
}




@media (max-width: 576px) {
    #technical_article .container {
        width: 90% !important;
    }
}